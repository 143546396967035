export class latloninfo{

country_code : string;
lat: number;
lon : number;
country : string
    
constructor(country__code, lat,lon, country){

    this.country_code = country__code;
    this.lat = lat;
    this.lon  = lon;
    this.country = country;}




}